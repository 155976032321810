import Event from "../Models/Event";

export default class NavigateUtils {
	/**
	 * Check if given url could be used for multiple events 
	 * 
	 * @returns Boolean
	 */
	static isMultiEventUrl = () => {
		// Remove the first "/" from the path (slice).
		const pathArray = window.location.pathname.slice(1).split("/");
		console.log(`PATH ARR: ${pathArray}`)
		const knownPaths = ['login', 'payment', 'exhibition', 'room', 'profile', 'speaker', 'page', 'session', 'videoChat', '404', ''];							
		
		if (pathArray.length >= 1 && !knownPaths.includes(pathArray[0])) {
			return true;
		}

		return false;
	}

	static navigate(reactNavigate: Function, event: Event, path: string) {
		let url = window.location.href;
		if(!path.startsWith('/')) {
			path = '/' + path;
		}
		if (process.env.REACT_APP_ENV == 'preview') {
			const previewKey = window.location.pathname.split('/')[1];
			path = "/" + previewKey + (path == "/" ? "" : path)
		} else if (process.env.REACT_APP_BASE_URL && url.includes(process.env.REACT_APP_BASE_URL)) {
			path = "/" + event.$organisation.slug + "/" + event.$slug + (path == "/" ? "" : path);
		} else if (NavigateUtils.isMultiEventUrl()) {
			path = "/" + event.$slug + (path == "/" ? "" : path)
		}

		if(path.includes('/videoChat/')) {
			window.location.pathname = path;
		} else {
			reactNavigate(path);
		}

	};
}