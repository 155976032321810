import axios from "axios"
import AuthService from "./AuthService"
import User from "../Models/User"

const UserService = {

	updateUser: async (eventId, user, extraFields = null) => {
		const obj = {};
		Object.assign(obj, user);
		obj['event_id'] = eventId;
		if(extraFields) {
			obj['extra_info'] = extraFields;
		}
		const response = await AuthService.put(
			"user/update", obj
		);

		return response;
	},
	uploadAvatar: async (imgData): Promise<string> => {
		const resp = await AuthService.post('user/avatar', {
			'type': 'profile_pictures',
			'data': imgData,
		});
		return resp.data.data;
	},
	iAmLoggedIn: async (eventId) => {
		await AuthService.post(`event/${eventId}/iAmLoggedIn`, {});
	},
	authenticate2FA: async (eventId, encrypted2FA) => {
		await AuthService.post(`event/${eventId}/authenticate2FA`, {data: encrypted2FA});
	},

	/**
	 * Get the current user instance from the API. 
	 * When the user is not authenticated anymore, the token
	 * will be removed.
	 * 
	 * @returns User|null Returns current User object or null when 
	 * 						no user authenticated
	 */
	getUser: async (): Promise<User|null> => {
		try {
			const resp = await AuthService.getMe();
			const _user = User.fromJSON(resp);

			return _user;
		} catch (e) {
			localStorage.removeItem("_myAraToken");
			return null;
		}
	}
}

export default UserService
