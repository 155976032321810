import React, { useState, useEffect, useContext, Suspense, useRef, useMemo } from 'react';
import Router from '../Router';
import { BrowserRouter, useNavigate, useParams } from 'react-router-dom';
import { default as Notifications } from "../HOC/Notifications/Notifications";
import { Context as EventContext } from "../context/EventContext";
import { Context as UserContext } from "../context/UserContext";
import { Context as LanguageContext } from "../context/LanguageContext";
import EventService from '../Services/EventService';
import { Footer, Header } from '../layout-components';
import InnerHTML from 'dangerously-set-html-content';
import Font from '../Models/Font';
import AuthService from '../Services/AuthService';
import Heartbeat from '../components/Heartbeat';
import UserBlocker from '../components/UserBlocker';
import ComponentRefresher from '../components/ComponentRefresher';
import LoadingUtils from '../utils/LoadingUtils';
import UserIsAlive from '../components/UserIsAlive';
import { GoogleOAuthProvider } from '@react-oauth/google';
import NavigateUtils from '../utils/NavigateUtils';


const AraRouter = () => {
	const { state, dispatch } = useContext(EventContext);
	const { userState, userDispatch } = useContext(UserContext);
	const { languageState, languageDispatch } = useContext(LanguageContext);
	const [basePath, setBasePath] = useState();
	

	const [appMargin, setAppMargin] = useState('0px');
	const [url, setUrl] = useState(window.location.origin);
	
	const [showError, setShowError] = useState(false)


	const eventLoadedRef = useRef(false);

	const googleClientId = useMemo(() => {
		if (!state.event.$oauthCredentials) return "";
		const creds = state.event.$oauthCredentials.find(a => a.type == 'GOOGLE');
		if (creds) {
			return creds['credentials']['client_id']
		}
		return "";
	}, [state.event, state.event.$oauthCredentials])

	useEffect(() => {
		init();
	}, [])


	const init = async () => {
		// This only needs to be done if no payment route is used
		if (window.location.pathname.includes("payment")) return;
		await getMe();
	}

	const getMe = async () => {
		if (userState.user.$id) return;
		try {
			const resp = await AuthService.getMe();
			userDispatch({
				type: 'SET_USER',
				user: resp,
			});
			await initEvent();
		} catch (e) {
			//let organisationSlug;
			//let eventSlug;

			const slugs = getSlugs();

			/*if (window.location.href.includes(process.env.REACT_APP_BASE_URL)) {
				const parts = window.location.pathname.split('/');
				if (parts.length > 2) {
					organisationSlug = parts[1];
					eventSlug = parts[2];
				}
			}*/

			if (!window.location.href.includes('/login') && !window.location.href.includes('404')) {
				localStorage.setItem('source_url_' + slugs.organisationSlug + '_' + slugs.eventSlug, window.location.href);
				navigate('/login')
			}

		}
	}

	const reactNavigate = useNavigate();

	const navigate = (path: string) => {
		const slugs = getSlugs();
		let url = window.location.href;
		if (process.env.REACT_APP_BASE_URL && url.includes(process.env.REACT_APP_BASE_URL)) {
			if (slugs.eventSlug && slugs.organisationSlug) {
				path = "/" + slugs.organisationSlug + "/" + slugs.eventSlug + (path == "/" ? "" : path);
			} else {
				path = "/404"
			}
		}
		let p;
		if (!url.includes(process.env.REACT_APP_BASE_URL) && slugs.eventSlug) {
			p = "/" + slugs.eventSlug + path;
		}
		else {
			p = path;
		}
		console.log(`PATH IN NAV: ${p}`);
		reactNavigate(p);
	}

	const getSlugs = () => {
		let organisationSlug;
		let eventSlug;
		
		const parts = window.location.pathname.split('/');
		if (window.location.href.includes(process.env.REACT_APP_BASE_URL)) {	
			if (parts.length > 2) {
				organisationSlug = parts[1];
				eventSlug = parts[2];
			}
		} else if (NavigateUtils.isMultiEventUrl()) {
			// is multi event url
			console.log("PARTS");
			console.log(parts);
			if (parts.length >= 2) {
				eventSlug = parts[1];
			}
		}
		// When the given URL is event url -> then organsitationSLug and 
		// eventSlug MUST be empty

		return { 'organisationSlug': organisationSlug, 'eventSlug': eventSlug }
	}

	const initEvent = () => {
		if (eventLoadedRef.current) return;

		let _url = url;

		const slugs = getSlugs();


		if (!slugs.organisationSlug) {
			_url = window.location.protocol + "//" + window.location.host + window.location.pathname
			setUrl(_url);
		}

		dispatch({
			type: 'FETCH',
			url: _url,
			organisationSlug: slugs.organisationSlug,
			eventSlug: slugs.eventSlug,
			callback: setupEvent,
			errorCallback: errorCallback.bind(null, slugs.organisationSlug, slugs.eventSlug),
		});

		eventLoadedRef.current = true;

	}

	const setupEvent = (tempPath, faviconHref, title, translationData, event) => {
		document.title = title;
		if (faviconHref) {
			_setFavIcon(faviconHref);
		}
		eventLoadedRef.current = true
		setupLanguages(event.$id, translationData);
		LoadingUtils.storeLoadingSettings(event.$loading_settings, event.$organisation.slug, event.$slug);

		//setupDefaultLanguage(defaultLanguage);
	};

	const setupLanguages = async (eventId, translationData) => {
		languageDispatch({
			type: 'SET_TRANSLATION_DATA',
			data: translationData,
			eventId: eventId,
		});

	}


	const goToLogin = (organisationSlug, eventSlug) => {
		var loginUrl = `/${organisationSlug}/${eventSlug}/login?`;

		if (!organisationSlug && !eventSlug) {
			loginUrl = '/login?';
		} else if (!organisationSlug && eventSlug) {
			console.log("HERE");
			loginUrl = `/${eventSlug}/login?`;
		}

		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		urlParams.forEach((val, key) => {
			loginUrl += key + "=" + val + "&"
		})

		if (!window.location.href.includes('/login')) {
			window.location.href = loginUrl;
		}
	}

	const errorCallback = (organisationSlug, eventSlug, errorCode) => {

		try {
			localStorage.removeItem('_myAraToken');
			if (!window.location.href.includes('/login'))
				localStorage.setItem('source_url_' + organisationSlug + '_' + eventSlug, window.location.href);
		} catch (e) { }
		console.error(errorCode);
		switch (errorCode) {
			case 'not_logged_in':
				goToLogin(organisationSlug, eventSlug);
				break;
			case 'no_access_to_event':
				goToLogin(organisationSlug, eventSlug);
				break;
			default:
				navigate("/404")
				setShowError(true);
				break;
		}
	};

	const _setFavIcon = (faviconHref) => {
		const favicon = document.getElementById('favicon');
		favicon.href = faviconHref;
	};

	const onSidebarToggle = (isOpen, sidebarWidth, isMobile) => {

		if (isOpen && !isMobile) {

			setAppMargin(sidebarWidth + 'px');
		} else {
			setAppMargin('0px')
		}
	}

	const renderScripts = () => {
		return (
			<>
				{state.event.$fonts && state.event.$fonts.length > 0 ? (
					<style>
						{state.event.$fonts.map((font: Font, index) => {
							const fontDefinition = `
								@font-face {
									font-family: "${font.$font_family}";
									src: url("${font.$url}") format("${font.$file_extension}");
									font-display: swap;
									font-style: ${font.$font_style};
									font-weight: ${font.$font_weight};
								}
							`;

							const subjects = `
								${font.$applied_to?.join(', ')} {
									font-family: "${font.$font_family}";
									font-style: ${font.$font_style};
									font-weight: ${font.$font_weight};
								}
							`;


							return fontDefinition + subjects;


						})}
					</style>

				) : ''}
				{state.event.eventPageScripts ?
					<InnerHTML html={state.event.eventPageScripts} />
					: ""}
				<style>{state.event.$css}</style>
			</>
		);
	};

	const renderContent = () => {
		return (
			<>

				{state.event.$background_type == 'image' ? (
					<div
						className="event-background"
						style={{
							backgroundImage: `url(${state.event.$background})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							backgroundPosition: 'center',
						}}
					>
						{renderBody()}
					</div>
				) : ''}
				{state.event.$background_type == 'pattern' ? (
					<div
						className="event-background"
						style={{
							backgroundImage: `url(${state.event.$background})`,
							backgroundRepeat: 'repeat',
						}}
					>
						{renderBody()}
					</div>
				) : ''}
				{state.event.$background_type == 'color' ? (
					<div
						className="event-background"
						style={{
							backgroundColor: state.event.$background,
						}}
					>
						{renderBody()}
					</div>
				) : ''}
				<ComponentRefresher
					refreshTopic={"/event/" + state.event.$id}
				/>

			</>
		)
	}

	const bodyClass = useMemo(() => {
		let className = 'app-content--inner';
		if (userState.user.$id && userState.user.$paymentPlans) {
			for (let plan of userState.user.$paymentPlans) {
				className += " payment-plan-" + plan.$id + "-body"
			}
		}

		return className;
	}, [userState.user])

	const renderBody = () => {
		return (
			<div className="app-main" style={{ marginRight: appMargin, transition: "all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1)" }}>

				{!window.location.pathname.includes("videoChat") ? (
					<>
						<Header
							user={userState.user}
							color={state.event.$color}
							logo={state.event.$logo}
							title={state.event.$title}
							showTitle={state.event.$show_title}
							onSidebarToggle={onSidebarToggle}
						/>
						<div className={"app-content"}>
							<div className={bodyClass}>
								<div className="app-content--inner__wrapper">
									<Router />
								</div>
							</div>
						</div>
						<Footer />
					</>
				) : <Router />}
			</div>
		)
	}

	return (
		<>
			{eventLoadedRef.current ? (

				<Suspense fallback={<Router />}>
					{renderScripts()}
					<Heartbeat
						heartbeatInterval={
							Math.round((60 + Math.random() * 60) * 1000)
						}
					/>
					<UserBlocker />
					{renderContent()}
				</Suspense>

			) : (
				<Router />
			)}
			<Notifications />
			<UserIsAlive />
		</>
	)
}
export default AraRouter;