import React, { lazy, Suspense, useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';
import { withNotifications } from './HOC/Notifications';
import ParentRouteWithContent from './utils/ParentRouteWithContent';
import NavigateUtils from './utils/NavigateUtils';

const PageLogin = lazy(() => import('./pages/PageLogin/PageLogin'));
const Home = lazy(() => import('./pages/Home'));
const PageExhibition = lazy(() => import('./pages/PageExhibition/index'));
const VideoChat = lazy(() => import('./pages/PageVideoChat'));
const Profile = lazy(() => import('./pages/Profile'));

// Error pages
const Page404 = lazy(() => import('./pages/errorPages/Page404'));
const PageRoom = lazy(() => import('./pages/PageRoom'));
const PageSpeaker = lazy(() => import('./pages/PageSpeaker'));
const PageSession = lazy(() => import('./pages/PageSession/index'));
const PreviewHome = lazy(() => import('./pages/PreviewHome'));
const PagePayments = lazy(() => import('./pages/PagePayment'));
const PageEventPage = lazy(() => import('./pages/PageEventPage'));
const EventLoader = lazy(() => import('./utils/EventLoader'));

const Router = () => {
	const [url, setUrl] = useState(window.location.origin);
	const [path, setPath] = useState(window.location.href);

	const pageVariants = {
		initial: {
			opacity: 0,
			scale: 0.99,
		},
		in: {
			opacity: 1,
			scale: 1,
		},
		out: {
			opacity: 0,
			scale: 1.01,
		},
	};

	const pageTransition = {
		type: 'tween',
		ease: 'anticipate',
		duration: 0.4,
	};


	/**
	 * Check if the url is the main url.
	 * @returns Boolean
	 */
	const isMainOriginUrl = () => {
		const res = process.env.REACT_APP_BASE_URL.replace("http://", "").replace("https://", "") === url.replace("http://", "").replace("https://", "")
		return res;
	}

	/**
	 * Check if given url could be used for multiple events 
	 * 
	 * @returns Boolean
	 */
	const isMultiEventUrl = () => {
		const pathArray = path.replace(url + "/", "")
							.replace(url, "").split("/");
		const knownPaths = ['login', 'payment', 'exhibition', 'room', 'profile', 'speaker', 'page', 'session', 'videoChat', '404', ''];

		if (pathArray.length >= 1 && !knownPaths.includes(pathArray[0])) {
			return true;
		}
		return false;
	}

	// TODO: customize loading
	const SuspenseLoading = () => {
		return (
			<>
				<div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
					<div className="d-flex align-items-center flex-column px-4">
						<ClimbingBoxLoader color={'#3c44b1'} loading={true} />
					</div>
					<div className="text-muted font-size-xl text-center pt-3">
						Please wait while we load the live preview examples
						<span className="font-size-lg d-block text-dark">
							This live preview instance can be slower than a real
							production build!
						</span>
					</div>
				</div>
			</>
		);
	};

	return (
		<AnimatePresence>
			<Suspense fallback={<EventLoader />}>
				{process.env.REACT_APP_ENV == 'preview' ? (
					<>
						<style>{`
						input {
							pointer-events: none;
							background: #efefef !important;
						}
						.preview-alert {
							width: 100%;
							position: fixed;
							z-index: 100;
						}
						.app-main {
							padding-top: 3rem;
						}
					`}</style>

						<Routes>
							<Route path='404' element={<Page404 />} />
							<Route path=':previewSlug' element={<ParentRouteWithContent content={<PreviewHome />} />} >
								<Route path='exhibition/:exhibitionSlug' element={<PageExhibition />} />
								<Route path='room/:roomSlug' element={<PageRoom />} />
								<Route path='speaker/:speakerSlug' element={<PageSpeaker />} />
								<Route path='session/:sessionSlug' element={<PageSession />} />
								<Route path='page/:pageSlug' element={<PageEventPage />} />
								<Route path='videoChat/:videoRoomId' element={<VideoChat />} />
							</Route>
							<Route path=':organisationSlug/:eventSlug/profile' element={<Profile />} />

						</Routes>
					</>
				) : (
					<>
						{isMainOriginUrl() ?
							<Routes>

								<Route path='404' element={<Page404 />} />
								<Route path=':organisationSlug/:eventSlug' element={<ParentRouteWithContent content={<Home />} />} >
									<Route path='login' element={<PageLogin />} />
									<Route path='payment' element={<PagePayments />} />
									<Route path='profile' element={<Profile />} />
									<Route path='exhibition/:exhibitionSlug' element={<PageExhibition />} />
									<Route path='room/:roomSlug' element={<PageRoom />} />
									<Route path='speaker/:speakerSlug' element={<PageSpeaker />} />
									<Route path='page/:pageSlug' element={<PageEventPage />} />
									<Route path='session/:sessionSlug' element={<PageSession />} />
									<Route path='videoChat/:videoRoomId' element={<VideoChat />} />
								</Route>
							</Routes>
							:
							NavigateUtils.isMultiEventUrl() ?
								<Routes>
									<Route path=':eventSlug' element={<ParentRouteWithContent content={<Home/>} />} >
										<Route path='404' element={<Page404 />} />
											<Route path='login' element={<PageLogin />} />
											<Route path='payment' element={<PagePayments />} />
											<Route path='profile' element={<Profile />} />
											<Route path='exhibition/:exhibitionSlug' element={<PageExhibition />} />
											<Route path='room/:roomSlug' element={<PageRoom />} />
											<Route path='speaker/:speakerSlug' element={<PageSpeaker />} />
											<Route path='session/:sessionSlug' element={<PageSession />} />
											<Route path='page/:pageSlug' element={<PageEventPage />} />
											<Route path='videoChat/:videoRoomId' element={<VideoChat />} />
									</Route>
								</Routes>
							: <Routes>
								<Route path='/' element={<ParentRouteWithContent content={<Home />} />} >
									<Route path='404' element={<Page404/>} />
									<Route path='login' element={<PageLogin />} />
									<Route path='payment' element={<PagePayments />} />
									<Route path='profile' element={<Profile />} />
									<Route path='exhibition/:exhibitionSlug' element={<PageExhibition />} />
									<Route path='room/:roomSlug' element={<PageRoom />} />
									<Route path='speaker/:speakerSlug' element={<PageSpeaker />} />
									<Route path='page/:pageSlug' element={<PageEventPage />} />
									<Route path='session/:sessionSlug' element={<PageSession />} />
									<Route path='videoChat/:videoRoomId' element={<VideoChat />} />
								</Route>
							</Routes> 
						}
					</>
				)}

			</Suspense>
		</AnimatePresence>
	);
};

export default withNotifications(Router);
